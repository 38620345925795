import {
  Button,
  Divider,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Select,
  Typography,
} from 'antd';
import React from 'react';
import SelectionTypeColumn from './selection-type-column/selection-type-column';
import NumberTypeColumn from './number-type-column/number-type-column';
import LabelTypeColumn from './label-type-column/label-type-column';
import FormulaTypeColumn from './formula-type-column/formula-type-column';
import { useAppSelector } from '../../../../../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../../../../../hooks/useAppDispatch';
import {
  CustomFieldNumberTypes,
  CustomFieldsTypes,
  setCustomFieldType,
} from '../../../../../../../features/projects/singleProject/task-list-custom-columns/task-list-custom-columns-slice';
import CustomColumnHeader from '../custom-column-header/custom-column-header';
import { nanoid } from '@reduxjs/toolkit';
import {
  addCustomColumn,
  CustomTableColumnsType,
  deleteCustomColumn,
  updateCustomColumn,
} from '../../../../../../../features/projects/singleProject/taskListColumns/taskColumnsSlice';
import { themeWiseColor } from '../../../../../../../utils/themeWiseColor';
import KeyTypeColumn from './key-type-column/key-type-column';

type CustomColumnModalProps = {
  modalType: 'create' | 'edit';
  isModalOpen: boolean;
  handleCancel: () => void;
  columnId?: string;
};

const CustomColumnModal = ({
  modalType,
  isModalOpen,
  handleCancel,
  columnId,
}: CustomColumnModalProps) => {
  const [mainForm] = Form.useForm();

  //   get theme details from theme reducer
  const themeMode = useAppSelector((state) => state.themeReducer.mode);

  const dispatch = useAppDispatch();

  // get initial data from task list custom column slice
  const fieldType: CustomFieldsTypes = useAppSelector(
    (state) => state.taskListCustomColumnsReducer.customFieldType
  );
  // number column initial data
  const numberType: CustomFieldNumberTypes = useAppSelector(
    (state) => state.taskListCustomColumnsReducer.customFieldNumberType
  );
  const decimals: number = useAppSelector(
    (state) => state.taskListCustomColumnsReducer.decimals
  );
  const label: string = useAppSelector(
    (state) => state.taskListCustomColumnsReducer.label
  );
  const labelPosition: 'left' | 'right' = useAppSelector(
    (state) => state.taskListCustomColumnsReducer.labelPosition
  );
  const previewValue: number = useAppSelector(
    (state) => state.taskListCustomColumnsReducer.previewValue
  );
  // formula column initial data
  const expression = useAppSelector(
    (state) => state.taskListCustomColumnsReducer.expression
  );
  const firstNumericColumn = useAppSelector(
    (state) => state.taskListCustomColumnsReducer.firstNumericColumn
  );
  const secondNumericColumn = useAppSelector(
    (state) => state.taskListCustomColumnsReducer.secondNumericColumn
  );
  // labels column initial state
  const labelsList = useAppSelector(
    (state) => state.taskListCustomColumnsReducer.labelsList
  );
  // selection column initial state
  const selectionsList = useAppSelector(
    (state) => state.taskListCustomColumnsReducer.selectionsList
  );

  // if it is already created column get the column data
  const openedColumn = useAppSelector(
    (state) => state.projectViewTaskListColumnsReducer.columnList
  ).find((col) => col.key === columnId);

  const fieldTypesOptions = [
    {
      key: 'people',
      value: 'people',
      label: 'People',
    },
    {
      key: 'number',
      value: 'number',
      label: 'Number',
    },
    {
      key: 'date',
      value: 'date',
      label: 'Date',
    },
    {
      key: 'selection',
      value: 'selection',
      label: 'Selection',
    },
    {
      key: 'checkbox',
      value: 'checkbox',
      label: 'Checkbox',
    },
    {
      key: 'labels',
      value: 'labels',
      label: 'Labels',
    },
    {
      key: 'key',
      value: 'key',
      label: 'Key',
    },
    {
      key: 'formula',
      value: 'formula',
      label: 'Formula',
    },
  ];

  // function to handle form submit
  const handleFormSubmit = async (value: any) => {
    try {
      if (modalType === 'create') {
        const columnKey = nanoid(); // this id is random and unique, generated by redux

        const newColumn: CustomTableColumnsType = {
          key: columnKey,
          name: value.fieldTitle,
          columnHeader: (
            <CustomColumnHeader
              columnKey={columnKey}
              columnName={value.fieldTitle}
            />
          ),
          width: 150,
          isVisible: true,
          isCustomColumn: true,
          customColumnObj: {
            ...value,
            labelsList: value.fieldType === 'labels' ? labelsList : [],
            selectionsList:
              value.fieldType === 'selection' ? selectionsList : [],
          },
        };

        await dispatch(addCustomColumn(newColumn));
        message.success('column added!');
      } else if (modalType === 'edit' && columnId) {
        const updatedColumn = openedColumn
          ? {
              ...openedColumn,
              name: value.fieldTitle,
              columnHeader: (
                <CustomColumnHeader
                  columnKey={columnId}
                  columnName={value.fieldTitle}
                />
              ),
              customColumnObj: {
                ...openedColumn.customColumnObj,
                fieldTitle: value.fieldTitle,
                fieldType: value.fieldType,
                numberType: value.numberType,
                decimals: value.decimals,
                label: value.label,
                labelPosition: value.labelPosition,
                previewValue: value.previewValue,
                expression: value.expression,
                firstNumericColumn: value.firstNumericColumn,
                secondNumericColumn: value.secondNumericColumn,
                labelsList: value.fieldType === 'labels' ? labelsList : [],
                selectionsList:
                  value.fieldType === 'selection' ? selectionsList : [],
              },
            }
          : null;

        if (updatedColumn) {
          await dispatch(updateCustomColumn({ key: columnId, updatedColumn }));
          message.success('column updated!');
        }
      }

      handleCancel();
      mainForm.resetFields();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      title={modalType === 'create' ? 'Add field' : 'Edit field'}
      centered
      open={isModalOpen}
      onCancel={handleCancel}
      styles={{
        header: { position: 'relative' },
        footer: { display: 'none' },
      }}
    >
      <Divider style={{ position: 'absolute', left: 0, top: 32 }} />

      <Form
        form={mainForm}
        layout="vertical"
        onFinish={handleFormSubmit}
        style={{ marginBlockStart: 24 }}
        initialValues={
          modalType === 'create'
            ? {
                fieldType,
                numberType,
                decimals,
                label,
                labelPosition,
                previewValue,
                expression,
                firstNumericColumn,
                secondNumericColumn,
              }
            : {
                fieldTitle: openedColumn?.customColumnObj.fieldTitle,
                fieldType: openedColumn?.customColumnObj.fieldType,
                numberType: openedColumn?.customColumnObj.numberType,
                decimals: openedColumn?.customColumnObj.decimals,
                label: openedColumn?.customColumnObj.label,
                labelPosition: openedColumn?.customColumnObj.labelPosition,
                previewValue: openedColumn?.customColumnObj.previewValue,
                expression: openedColumn?.customColumnObj.expression,
                firstNumericColumn:
                  openedColumn?.customColumnObj.firstNumericColumn,
                secondNumericColumn:
                  openedColumn?.customColumnObj.secondNumericColumn,
              }
        }
      >
        <Flex gap={16} align="center" justify="space-between">
          <Form.Item
            name={'fieldTitle'}
            label={<Typography.Text>Field title</Typography.Text>}
            layout="vertical"
            rules={[
              {
                required: true,
                message: 'Field title is required',
              },
            ]}
            required={false}
          >
            <Input
              placeholder="title"
              style={{ minWidth: '100%', width: 300 }}
            />
          </Form.Item>

          <Form.Item
            name={'fieldType'}
            label={<Typography.Text>Type</Typography.Text>}
            layout="vertical"
          >
            <Select
              options={fieldTypesOptions}
              defaultValue={fieldType}
              // disabled={modalType === 'edit'}
              value={fieldType}
              onChange={(value) => dispatch(setCustomFieldType(value))}
              style={{
                minWidth: '100%',
                width: 150,
                border: `1px solid ${themeWiseColor('#d9d9d9', '#424242', themeMode)}`,
                borderRadius: 4,
              }}
            />
          </Form.Item>
        </Flex>

        {/* render form items based on types  */}
        {fieldType === 'key' && <KeyTypeColumn />}
        {fieldType === 'number' && <NumberTypeColumn />}
        {fieldType === 'formula' && <FormulaTypeColumn />}
        {fieldType === 'labels' && <LabelTypeColumn />}
        {fieldType === 'selection' && <SelectionTypeColumn />}

        <Flex
          gap={8}
          align="center"
          justify={`${modalType === 'create' ? 'flex-end' : 'space-between'}`}
          style={{ marginBlockStart: 24 }}
        >
          {modalType === 'edit' && columnId && (
            <Button
              danger
              onClick={() => dispatch(deleteCustomColumn(columnId))}
            >
              Delete
            </Button>
          )}

          <Flex gap={8}>
            <Button onClick={handleCancel}>Cancel</Button>
            {modalType === 'create' ? (
              <Button type="primary" htmlType="submit">
                Create
              </Button>
            ) : (
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            )}
          </Flex>
        </Flex>
      </Form>

      <Divider style={{ position: 'absolute', left: 0, bottom: 42 }} />
    </Modal>
  );
};

export default CustomColumnModal;
